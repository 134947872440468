import React, { useContext } from 'react';
import { AppDataContext } from '../../context/AppDataProvider';

const InsightsPage = () => {
  const { siteData, isSiteDataLoading, error } = useContext(AppDataContext);

  if (isSiteDataLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const pageData = siteData; // Assuming Insights Page data is part of siteData

  const pageTitle = pageData?.seo?.pageTitle || 'Insights';
  const heroImageUrl = pageData?.seo?.heroImage // Adjust based on actual siteData structure
                       ? `${process.env.REACT_APP_STRAPI_BASE_URL}${pageData.seo.heroImage}` 
                       : '';

  return (
    <div className="insights-container">
      <header>
        {/* You can include site-wide header components here */}
      </header>
      <main>
        {pageTitle && <h1>{pageTitle}</h1>}
        {heroImageUrl && <img src={heroImageUrl} alt="Hero for Insights Page" />}
        {/* Add more insights-specific content here */}
      </main>
      <footer>
        {/* You can include site-wide footer components here */}
      </footer>
    </div>
  );
};

export default InsightsPage;
