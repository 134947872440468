import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppDataContext } from '../../context/AppDataProvider';
import './ArticleDetails.css';

const ArticleDetails = () => {
  const { year, month, day, slug } = useParams();
  const { fetchArticleDetails, articleDetails, isLoading, error } = useContext(AppDataContext);

  const fetchArticleDetailsRef = useRef(fetchArticleDetails);

  useEffect(() => {
    if (year && month && day && slug) {
      fetchArticleDetailsRef.current(year, month, day, slug);
      window.scrollTo(0, 0);
    }
  }, [year, month, day, slug]);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  if (!articleDetails || !articleDetails.data || !articleDetails.data.attributes) {
    return <div className="notFound">Article not found.</div>;
  }

  const { article_title, article_author, article_content, article_posted_date, article_source_url } =
    articleDetails.data.attributes;

  const formattedDate = article_posted_date
    ? new Date(article_posted_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : 'Unknown Date';

  // Sanitize content by removing unwanted text or elements
  const sanitizeContent = (content) => {
    if (!content) return content;

    // Parse the content as HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Remove specific structures matching the unwanted text
    const unwantedLinks = doc.body.querySelectorAll('a[href*="qrco.de/wbbhapp"]');
    unwantedLinks.forEach((link) => {
      const parent = link.closest('p'); // Target the nearest paragraph containing the link
      if (parent) {
        parent.remove();
      }
    });

    return doc.body.innerHTML; // Return sanitized HTML
  };

  const sanitizedContent = sanitizeContent(article_content);

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className="articleContainer">
      <h1 className="articleTitle">{article_title}</h1>
      <p className="articleMeta">
        Posted on: {formattedDate} | By: {article_author || 'Unknown'}
      </p>
      <div className="ad-space ad-top">
        <p>Advertisement - 728x90 leaderboard ads</p>
        <img src="path-to-banner-ad.jpg" alt="Top Banner Ad" />
      </div>
      <img
        src={
          articleDetails.data.attributes.article_scraped_image_url ||
          'path/to/default/image.jpg'
        }
        alt={article_title || 'Article Image'}
        className="articleImage"
        loading="lazy"
      />
      <div
        className="articleContent"
        dangerouslySetInnerHTML={createMarkup(sanitizedContent)}
      />
      <div className="ad-space ad-in-article">
        <p>Advertisement - 300x250 rectangle ads</p>
        <img src="path-to-in-article-ad.jpg" alt="In-Article Ad" />
      </div>
      {article_source_url && (
        <p className="articleSource">
          To view the original article,{' '}
          <a href={article_source_url} target="_blank" rel="noopener noreferrer">
            click here
          </a>.
        </p>
      )}
      <div className="ad-space ad-footer">
        <p>Advertisement - 728x90 leaderboard ads</p>
        <img src="path-to-footer-ad.jpg" alt="Footer Ad" />
      </div>
    </div>
  );
};

export default ArticleDetails;
