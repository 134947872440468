import React, { useContext, useEffect, useCallback } from 'react';
import ArticleCard from '../articles/ArticleCard';
import HeroSection from '../layout/HeroSection';
import Tag from '../articles/Tag'; // Ensure Tag is imported
import { AppDataContext } from '../../context/AppDataProvider';
import './Homepage.css'; // Assuming you have a CSS file

const Homepage = () => {
  const {
    tags,
    selectedTag,
    handleTagClick,
    nonFeaturedArticles,
    isLoading,
    error,
    loadMoreNonFeaturedArticles,
    homepageData,
  } = useContext(AppDataContext);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      loadMoreNonFeaturedArticles();
    }
  }, [loadMoreNonFeaturedArticles]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (isLoading && nonFeaturedArticles.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {homepageData?.homepage_title && (
        <div className="homepage-title">
          <h2>{homepageData.homepage_title}</h2>
        </div>
      )}
      <HeroSection />
      
      {/* Tag Container Implementation */}
      <div className="tag-container">
        {tags.map((tag, index) => (
          <Tag
            key={index}
            text={tag}
            onClick={() => handleTagClick(tag)}
            isSelected={tag === selectedTag}
          />
        ))}
      </div>
      
      <section className="articles">
        {nonFeaturedArticles.length > 0 ? (
          nonFeaturedArticles.map(article => (
            <ArticleCard key={article.id} article={article} />
          ))
        ) : (
          <p>No articles found.</p>
        )}
        {isLoading && <p>Loading more articles...</p>}
        {error && <p>Error loading articles: {error.message}</p>}
      </section>
    </>
  );
};

export default Homepage;
