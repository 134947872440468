import React, { useState, useEffect, useCallback } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../common/Logo';
import NavigationMenu from '../navigation/NavigationMenu';
import './Header.css';

const Header = ({ siteData }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [condensedHeader, setCondensedHeader] = useState(false);
  const { navigationItems = [], socialMediaLinks = [], websiteLogo, websiteTitle } = siteData || {};

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const controlHeader = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setCondensedHeader(true);
      } else {
        setCondensedHeader(false);
      }
      setLastScrollY(window.scrollY);
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);

      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [controlHeader]);

  const getFullUrl = (url) => {
    if (!url) return '';
    // Check if it's already an absolute URL
    if (url.startsWith('http')) {
      return url;
    }
    // Use REACT_APP_API_URL or a hardcoded fallback for local development
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:1337';
    return `${baseUrl}${url}`;
  };

  return (
    <header className={`header ${condensedHeader ? 'condensed' : ''}`}>
      <Logo logoUrl={getFullUrl(websiteLogo)} siteName={websiteTitle} />
      <FaBars className={`hamburger ${isMenuOpen ? 'hide' : ''}`} onClick={toggleMenu} />
      {isMenuOpen && <FaTimes className="close-menu is-active" onClick={toggleMenu} />}
      <NavigationMenu
        items={navigationItems}
        socialMediaLinks={socialMediaLinks}
        isMenuOpen={isMenuOpen}
        onLinkClick={toggleMenu}
      />
    </header>
  );
};

export default Header;
