import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AppDataContext } from '../../context/AppDataProvider';
import './ContactPage.css';

const ContactPage = () => {
  const { siteData, isSiteDataLoading, error } = useContext(AppDataContext);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionMessage('');
    try {
      await axios.post(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/contacts`, {
        data: formData,
      });
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      setSubmissionMessage('Thank you for your message! We will be in touch soon.');
    } catch (error) {
      setSubmissionMessage('An error occurred while submitting the form. Please try again later.');
      console.error('Error submitting contact form:', error);
    }
    setIsSubmitting(false);
  };

  if (isSiteDataLoading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (error) {
    return <div className="error-container">Error: {error.message}</div>;
  }

  const pageData = siteData; // Assuming Contact Page data is part of siteData

  const heroImageUrl = pageData?.footerText?.heroImage // Adjust based on actual siteData structure
                       ? `${process.env.REACT_APP_STRAPI_BASE_URL}${pageData.footerText.heroImage}` 
                       : '';

  return (
    <div className="contact-container">
      <header>
        {/* You can include site-wide header components here */}
      </header>
      <main>
        <h1>{pageData?.websiteTitle || 'Contact Us'}</h1>
        <div className="content-row">
          <h2 className="contact-description">{pageData?.seo?.description || "We'd love to hear from you!"}</h2>
          {heroImageUrl && 
            <img 
              src={heroImageUrl} 
              alt="Hero for Contact Page" 
              className="contact-page-hero-image"
            />
          }
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Your Name"
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder="Your Email"
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="subject"
            value={formData.subject}
            placeholder="Subject"
            onChange={handleInputChange}
            required
          />
          <textarea
            name="message"
            value={formData.message}
            placeholder="Your Message"
            onChange={handleInputChange}
            required
          />
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {submissionMessage && <p className="submission-message">{submissionMessage}</p>}
      </main>
      <footer>
        {/* You can include site-wide footer components here */}
      </footer>
    </div>
  );
};

export default ContactPage;
