// src/components/pages/AboutPage.jsx

import React, { useEffect, useContext } from 'react';
import { AppDataContext } from '../../context/AppDataProvider';
import AboutHeroSection from '../layout/AboutHeroSection';
import ContentSection from '../layout/ContentSection';
import './AboutPage.css';

const AboutPage = () => {
  const { aboutData, isAboutLoading, aboutError } = useContext(AppDataContext);

  useEffect(() => {
    if (aboutData) {
      // Any additional logic if needed
    }
  }, [aboutData]);

  if (isAboutLoading) {
    return <p>Loading...</p>;
  }

  if (aboutError) {
    return <div className="error-message">Error: {aboutError.message}</div>;
  }

  const blocks = aboutData.data.attributes.blocks;

  return (
    <div className="about-container">
      <header>
        {/* Add header content if needed */}
      </header>
      <main>
        {blocks.map((block, index) => {
          if (index === 0) {
            const imageUrl = block.image && block.image.length > 0 
              ? `${process.env.REACT_APP_STRAPI_BASE_URL}${block.image[0].image.data.attributes.url}`
              : '';
            return (
              <AboutHeroSection
                key={block.id}
                headline={block.headline}
                htmlContent={block.text}
                imageUrl={imageUrl}
                imageAlt={block.image[0]?.alt || 'SWFL.IO'}
              />
            );
          } else {
            return (
              <ContentSection
                key={block.id}
                headline={block.headline}
                htmlContent={block.text}
              />
            );
          }
        })}
      </main>
    </div>
  );
};

export default AboutPage;
