// src/App.js

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppDataContext, AppDataProvider } from './context/AppDataProvider';
import Layout from './components/layout/Layout';
import Homepage from './components/pages/Homepage';
import ArticleDetails from './components/pages/ArticleDetails';
import LocalNewsPage from './components/pages/LocalNewsPage';
import AboutPage from './components/pages/AboutPage';
import ContactPage from './components/pages/ContactPage';
import InsightsPage from './components/pages/InsightsPage';

function AppContent() {
  const { siteData, isSiteDataLoading } = useContext(AppDataContext);

  return (
    <Router>
      <Layout siteData={siteData} isLoading={isSiteDataLoading}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/:year/:month/:day/:slug" element={<ArticleDetails />} />
          <Route path="/local-news" element={<LocalNewsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/insights" element={<InsightsPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

function App() {
  return (
    <AppDataProvider>
      <AppContent />
    </AppDataProvider>
  );
}

export default App;
