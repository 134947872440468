import React, { useContext } from 'react';
import ArticleCard from '../articles/ArticleCard';
import { AppDataContext } from '../../context/AppDataProvider';
import './LocalNewsPage.css';

const LocalNewsPage = () => {
  const { allArticles, isLoading, error } = useContext(AppDataContext);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const pageTitle = "Local News"; // Assuming page title is static or fetched differently

  return (
    <>
      <div className="local-news-page-title">
        <h2>{pageTitle}</h2>
      </div>
      <section className="articles">
        {allArticles && allArticles.map((article, index) => (
          <ArticleCard key={index} article={article} />
        ))}
      </section>
    </>
  );
};

export default LocalNewsPage;
