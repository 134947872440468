import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
  },
});

axiosInstance.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
