import React, { useCallback } from 'react';
import Tag from './Tag';
import './ArticleCard.css';
import { useNavigate } from 'react-router-dom';

const ArticleCard = React.memo(({ article }) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    const date = new Date(article.attributes.article_posted_date);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    const url = `/${year}/${month}/${day}/${article.attributes.slug}`;
    navigate(url);
  }, [navigate, article.attributes.article_posted_date, article.attributes.slug]);

  const articleDate = new Date(article.attributes.article_DateTimeStamp);
  const now = new Date();
  const timeDifference = Math.floor((now - articleDate) / 1000); // Difference in seconds

  let formattedDate;
  if (timeDifference < 3600) { // Less than 1 hour
    if (timeDifference < 60) {
      formattedDate = 'Just now';
    } else {
      const minutesAgo = Math.floor(timeDifference / 60);
      formattedDate = `${minutesAgo} min${minutesAgo > 1 ? 's' : ''} ago`;
    }
  } else if (timeDifference < 86400) { // Less than 24 hours
    const hoursAgo = Math.floor(timeDifference / 3600);
    formattedDate = `${hoursAgo} hr${hoursAgo > 1 ? 's' : ''} ago`;
  } else {
    const year = String(articleDate.getFullYear()).slice(-2); // Get the last two digits of the year
    const month = String(articleDate.getMonth() + 1).padStart(2, '0');
    const day = String(articleDate.getDate()).padStart(2, '0');

    formattedDate = `${month}/${day}/${year}`;
  }

  // Sanitize the author name by removing any "By " prefix
  const rawAuthorName = article.attributes.article_author || '';
  const sanitizedAuthorName = rawAuthorName.startsWith('By ')
    ? rawAuthorName.slice(3) // Remove the "By " prefix
    : rawAuthorName;

  const imageUrl = article.attributes.article_image.data?.[0]?.attributes?.url
    ? `${process.env.REACT_APP_STRAPI_BASE_URL}${article.attributes.article_image.data[0].attributes.url}`
    : article.attributes.article_scraped_image_url || '';

  return (
    <div className="article-card">
      <div
        className="article-image-container"
        onClick={handleNavigate}
        style={{ cursor: 'pointer' }}
      >
        <img
          src={imageUrl}
          alt={article.attributes.article_title}
          className="article-image"
        />
        {/* Tag overlay */}
        <div className="article-tag">{article.attributes.article_category}</div>
      </div>
      <div className="article-text-area">
        <div
          className="article-title"
          onClick={handleNavigate}
          style={{ cursor: 'pointer' }}
        >
          {article.attributes.article_title}
        </div>
        <div className="article-meta">
          <div className="article-date">
            {formattedDate}{' '}
            <span className="article-author">by {sanitizedAuthorName}</span>
          </div>
          <div
            className="article-button"
            onClick={handleNavigate}
            style={{ cursor: 'pointer' }}
          >
            Read More
          </div>
        </div>
      </div>
    </div>
  );
});

export default ArticleCard;
