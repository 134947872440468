import React, { useContext } from 'react';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';
import { AppDataContext } from '../../context/AppDataProvider';

const HeroSection = () => {
  const navigate = useNavigate();
  const { featuredArticles, isLoading } = useContext(AppDataContext);

  if (isLoading) return <div>Loading...</div>;
  if (!featuredArticles?.length) return <div>No featured article available.</div>;

  const { attributes } = featuredArticles[0];
  const {
    article_title,
    article_content,
    article_category,
    article_image,
    article_scraped_image_url,
    article_author,
  } = attributes;

  const imageUrl = article_image?.data?.length
    ? `${process.env.REACT_APP_STRAPI_BASE_URL}${article_image.data[0].attributes.url}`
    : article_scraped_image_url || 'path/to/default/image.jpg';

  // Strip HTML tags for content preview
  const stripHTML = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const contentPreview = article_content
    ? `${stripHTML(article_content).substring(0, 430)}...`
    : '';

  // Format the article's date
  const articleDate = new Date(attributes.article_DateTimeStamp);
  const now = new Date();
  const timeDifference = Math.floor((now - articleDate) / 1000);

  const formattedDate =
    timeDifference < 3600
      ? `${Math.floor(timeDifference / 60)} mins ago`
      : timeDifference < 86400
      ? `${Math.floor(timeDifference / 3600)} hrs ago`
      : `${String(articleDate.getMonth() + 1).padStart(2, '0')}/${String(
          articleDate.getDate()
        ).padStart(2, '0')}/${String(articleDate.getFullYear()).slice(-2)}`;

  const goToArticleDetails = () => {
    const date = new Date(attributes.article_posted_date);
    const url = `/${date.getUTCFullYear()}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(
      date.getUTCDate()
    ).padStart(2, '0')}/${attributes.slug}`;
    navigate(url);
  };

  return (
    <section className="hero-section">
      <div className="hero-card" onClick={goToArticleDetails}>
        <div className="hero-image-container">
          <img className="hero-section-image" src={imageUrl} alt={article_title || 'Featured Article'} />
          {article_category && <div className="hero-tag">{article_category}</div>}
        </div>
        <div className="text-area">
          <div className="hero-headline">{article_title}</div>
          <p className="paragraph">{contentPreview}</p>
          <p className="article-date">
            {formattedDate} {article_author && <span className="article-author">by {article_author}</span>}
          </p>
          <Button text="Read Now" className="button" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
