// src/components/layout/Footer.jsx

import React, { useContext } from 'react';
import { AppDataContext } from '../../context/AppDataProvider'; // Ensure the path is correct
import Logo from '../common/Logo';
import SocialMedia from '../common/SocialMedia';
import './Footer.css';

const Footer = () => {
  const {
    latestArticles, // Uncommented to use latestArticles
    isLoading,
    siteData,
    error, // Optional: Handle errors if needed
  } = useContext(AppDataContext);

  const {
    websiteLogo = '',
    websiteTitle = '',
    footerNavigationLinks = [],
    footerText = '',
    socialMediaLinks = [],
  } = siteData || {};

  // Group navigation links excluding 'Latest News'
  const groupedLinks = footerNavigationLinks.reduce((acc, link) => {
    const { footerLinkColumns, text, path } = link;
    if (footerLinkColumns !== 'Latest News') {
      acc[footerLinkColumns] = acc[footerLinkColumns] || [];
      acc[footerLinkColumns].push({ text, path });
    }
    return acc;
  }, {});

  const columns = Object.keys(groupedLinks).map((columnTitle) => ({
    title: columnTitle,
    links: groupedLinks[columnTitle],
  }));

  // Function to generate article URL
  const generateArticleUrl = (article) => {
    const date = new Date(article.attributes.article_posted_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `/${year}/${month}/${day}/${article.attributes.article_slug}`;
  };

  return (
    <footer className="footer">
      {/* Top Row: Logo */}
      <div className="top-row">
        <Logo logoUrl={websiteLogo} siteName={websiteTitle} />
      </div>

      {/* Middle Row: Navigation Links and Latest News */}
      <div className="link-column-container">
        {/* Navigation Links */}
        {columns.map((column, index) => (
          <div className="link-column" key={index}>
            <h3 className="link-column-title">{column.title}</h3>
            <ul className="link-list">
              {column.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <a href={link.path} className="footer-link">
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        {/* Latest News */}
        <div className="link-column latest-news">
          <h3 className="link-column-title">Latest News</h3>
          {isLoading ? (
            <p>Loading latest news...</p>
          ) : error ? (
            <p>Error loading latest news.</p>
          ) : latestArticles && latestArticles.length > 0 ? (
            <ul className="link-list">
              {latestArticles.slice(0, 5).map((article, index) => (
                <li key={index}>
                  <a href={article.url} className="footer-link">
                    {article.attributes.article_title}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No latest news available.</p>
          )}
        </div>
      </div>

      {/* Bottom Row: Social Media and Footer Text */}
      <div className="bottom-row">
        <SocialMedia icons={socialMediaLinks} />
        <p className="footer-text">{footerText}</p>
      </div>
    </footer>
  );
};

export default Footer;
