// src/components/providers/AppDataProvider.jsx

import React, { createContext, useState, useEffect, useMemo } from 'react';
import axiosInstance from '../services/axiosConfig';

// Create the context
export const AppDataContext = createContext();

// Create the provider component
export const AppDataProvider = ({ children }) => {
  // State for tags
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  // State for articles
  const [allArticles, setAllArticles] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [nonFeaturedArticles, setNonFeaturedArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [articleDetails, setArticleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nonFeaturedPage, setNonFeaturedPage] = useState(1);
  const [hasMoreNonFeatured, setHasMoreNonFeatured] = useState(true);
  const [error, setError] = useState(null);

  // State for homepage data
  const [homepageData, setHomepageData] = useState(null);

  // State for site data
  const [siteData, setSiteData] = useState(null);
  const [isSiteDataLoading, setIsSiteDataLoading] = useState(true);

  // State for About Data
  const [aboutData, setAboutData] = useState(null);
  const [isAboutLoading, setIsAboutLoading] = useState(true);
  const [aboutError, setAboutError] = useState(null);

  // Fetch tags from articles
  const fetchTags = async () => {
    try {
      const response = await axiosInstance.get('/api/articles?populate[SEO]=*&populate[article_image]=*');
      const articleData = response.data;

      const categoryTags = articleData.data.map(article => article.attributes.article_category);
      const uniqueCategoryTags = [...new Set(categoryTags)];
      setTags(uniqueCategoryTags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setError(error);
    }
  };

  // Function to generate URL for articles
  const generateUrl = (article) => {
    const date = new Date(article.attributes.article_posted_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `/${year}/${month}/${day}/${article.attributes.article_slug}`;
  };

  // Function to fetch About Data
  const fetchAboutData = async () => {
    try {
      const response = await axiosInstance.get(`/api/aboutpage?populate[blocks][populate][image][populate]=*`);
      setAboutData(response.data);
    } catch (e) {
      setAboutError(e);
    } finally {
      setIsAboutLoading(false);
    }
  };

  // Fetch About Data
  useEffect(() => {
    fetchAboutData();
  }, []);

  // Fetch all articles including featured, latest, and non-featured using Promise.all
  const fetchAllArticles = async () => {
    setIsLoading(true);
    try {
      // Define all API requests
      const allArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*`);
      const featuredArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=true`);
      const latestArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&_limit=5`);
      const nonFeaturedArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=1&pagination[pageSize]=25`);

      // Execute all requests in parallel
      const [allData, featuredData, latestData, nonFeaturedData] = await Promise.all([
        allArticlesRequest,
        featuredArticlesRequest,
        latestArticlesRequest,
        nonFeaturedArticlesRequest
      ]);

      // Process and set all articles
      setAllArticles(allData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set featured articles
      setFeaturedArticles(featuredData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set latest articles
      setLatestArticles(latestData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set non-featured articles
      setNonFeaturedArticles(nonFeaturedData.data.data.map(article => ({ ...article, url: generateUrl(article) })));
      setHasMoreNonFeatured(1 < nonFeaturedData.data.meta.pagination.pageCount);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch non-featured articles when page changes
  const fetchNonFeaturedArticles = async (page) => {
    setIsLoading(true);
    try {
      const { data: nonFeaturedData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=${page}&pagination[pageSize]=25`);
      const newArticles = nonFeaturedData.data.map(article => ({ ...article, url: generateUrl(article) }));

      setNonFeaturedArticles(prevArticles => {
        const articlesMap = new Map(prevArticles.map(article => [article.id, article]));
        newArticles.forEach(article => articlesMap.set(article.id, article));
        return Array.from(articlesMap.values());
      });

      setHasMoreNonFeatured(page < nonFeaturedData.meta.pagination.pageCount);
    } catch (error) {
      console.error('Error fetching non-featured articles:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch article details
  const fetchArticleDetails = async (year, month, day, title) => {
    setIsLoading(true);
    const url = `/api/articles/${year}/${month}/${day}/${title}?populate[SEO]=*&populate[article_image]=*`;
    try {
      const { data } = await axiosInstance.get(url);
      setArticleDetails(data);
    } catch (error) {
      console.error(`Error fetching article details:`, error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch homepage data
  const fetchHomepageData = async () => {
    try {
      const response = await axiosInstance.get('/api/homepage');
      setHomepageData(response.data.data.attributes);
    } catch (error) {
      console.error('Error fetching homepage data:', error);
      setError(error);
    }
  };

  // Load more non-featured articles
  const loadMoreNonFeaturedArticles = () => {
    if (hasMoreNonFeatured) {
      setNonFeaturedPage(prevPage => prevPage + 1);
    }
  };

  // Handler for tag click
  const handleTagClick = (tag) => {
    setSelectedTag(prevTag => (prevTag === tag ? null : tag));
  };

  // Filtered and sorted articles based on selectedTag
  const filteredArticles = selectedTag
    ? nonFeaturedArticles.filter(article => article.attributes.article_category === selectedTag)
    : nonFeaturedArticles;

    const sortedArticles = filteredArticles.sort(
      (a, b) => new Date(b.attributes.article_posted_date) - new Date(a.attributes.article_posted_date)
    );
    

  // Integrated site data fetching logic
  useEffect(() => {
    async function fetchSiteData() {
      setIsSiteDataLoading(true);
      try {
        const siteDataResponsePromise = axiosInstance.get('/api/site-data?populate[seo]=*&populate[social_media_links]=*&populate[website_logo]=*');
        const headerNavResponsePromise = axiosInstance.get('/api/navigations/1?populate[navigation_links]=*');
        const footerNavResponsePromise = axiosInstance.get('/api/navigations/2?populate[navigation_links]=*');

        const [siteResponse, headerNavResponse, footerNavResponse] = await Promise.all([
          siteDataResponsePromise, 
          headerNavResponsePromise, 
          footerNavResponsePromise
        ]);

        const activeHeaderNavigationItems = headerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);
        const activeFooterNavigationItems = footerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);

        const websiteLogoData = siteResponse.data.data.attributes.website_logo.data;
        const websiteLogoUrl = websiteLogoData && websiteLogoData.length > 0 && websiteLogoData[0].attributes.url 
          ? websiteLogoData[0].attributes.url 
          : '';

        const siteDataFormatted = {
          websiteTitle: siteResponse.data.data.attributes.website_title,
          websiteLogo: websiteLogoUrl,
          socialMediaLinks: siteResponse.data.data.attributes.social_media_links.map(link => ({
            ...link,
            logo_url: link.logo_url || ''
          })),
          seo: siteResponse.data.data.attributes.seo,
          navigationItems: activeHeaderNavigationItems,
          footerNavigationLinks: activeFooterNavigationItems.map(link => ({
            text: link.link_name,
            path: link.link_url,
            highlighted: link.is_Featured_Link,
            footerLinkColumns: link.footer_link_columns
          })),
          footerText: siteResponse.data.data.attributes.footer_text,
        };

        setSiteData(siteDataFormatted);
      } catch (error) {
        console.error('Error fetching site data:', error);
        setError(error);
      } finally {
        setIsSiteDataLoading(false);
      }
    }

    fetchSiteData();
  }, []);

  // Initial data fetch and setup interval for refreshing articles
  useEffect(() => {
    fetchAllArticles();
    fetchHomepageData();
    fetchTags();

    const timerId = setInterval(fetchAllArticles, 300000); // Refresh every 5 minutes
    return () => clearInterval(timerId);
  }, []);

  // Fetch additional non-featured articles when page changes
  useEffect(() => {
    if (nonFeaturedPage > 1) {
      fetchNonFeaturedArticles(nonFeaturedPage);
    }
  }, [nonFeaturedPage]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    tags,
    selectedTag,
    handleTagClick,
    allArticles,
    featuredArticles,
    nonFeaturedArticles: sortedArticles,
    latestArticles,
    articleDetails,
    isLoading,
    loadMoreNonFeaturedArticles,
    hasMoreNonFeatured,
    fetchArticleDetails,
    homepageData,
    siteData,
    isSiteDataLoading,
    error,
    aboutData,
    isAboutLoading,
    aboutError,
  }), [
    tags,
    selectedTag,
    allArticles,
    featuredArticles,
    sortedArticles,
    latestArticles,
    articleDetails,
    isLoading,
    hasMoreNonFeatured,
    homepageData,
    siteData,
    isSiteDataLoading,
    error,
    aboutData,
    isAboutLoading,
    aboutError,
  ]);

  return (
    <AppDataContext.Provider value={contextValue}>
      {children}
    </AppDataContext.Provider>
  );
};
